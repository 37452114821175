<template>
  <div class="download-client">
    <el-dialog title :visible.sync="dialogVisible" :lock-scroll="false">
      <p
        style="text-align:center; margin-bottom:20px; font-size:20px; font-weight:bolder;"
      >如果没有打开客户端请点击下载</p>
      <div style="text-align:center;">
        <el-button
          type="danger"
          round
          style="width:200px;"
          @click="downloadClient('windows')"
        >Windows版下载</el-button>
        <el-button type="danger" round style="width:200px;" @click="downloadClient('mac')">Mac版下载</el-button>
      </div>
    </el-dialog>
    <iframe
      v-if="iframeSrc"
      :src="iframeSrc"
      width="1"
      height="1"
      style="display:none;"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'download-client',
  data() {
    return {
      iframeSrc: false,
      dialogVisible: false,
    }
  },
  created() {
    // 3秒后弹出下载提示框
    setTimeout(() => {
      this.dialogVisible = true
    }, 3000)
    // 尝试打开客户端
    this.iframeSrc = this.$route.query.url
  },
  methods: {
    //下载客户端
    downloadClient(type) {
      switch (type) {
        case 'windows':
          window.location.href = 'http://www.baijiayun.com/default/home/liveclientDownload?type=windows&partner_id=20000000'
          break;
        case 'mac':
          window.location.href = 'http://www.baijiayun.com/default/home/liveclientDownload?type=mac&partner_id=20000000'
          break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.download-client {
  .el-button--danger {
    background-color: $primary-color;
    border-color: $primary-color;
  }
}
</style>